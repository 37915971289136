<template>
    <section id="testimonials" class="section has-background-white-ter">
        <div class="container">
            <h1 class="title has-text-centered section-title has-text-link">Testimonials</h1>
            <div class="columns is-multiline is-centered is-vcentered">
                <testimonial v-for="(testimonial, i) in testimonials"
                             :testimonial="testimonial"
                             :key="`testimonial-${i}`" />
            </div>
        </div>
    </section>
</template>

<script>
import Testimonial from './testimonial';

export default {
    props: {
        testimonials: Array
    },
    components: {
        Testimonial,
    }
}
</script>

<style scoped>

</style>