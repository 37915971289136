import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/admin',
    // route level code-splitting
    // this generates a separate chunk (admin.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    beforeEnter: (to, from, next) => {
      let user = window.netlifyIdentity.currentUser();

      // Login if needed
      if (!user) {
        window.netlifyIdentity.open();

        window.netlifyIdentity.on('login', (user) => {
          window.netlifyIdentity.close();
          let { roles } = user.app_metadata;

          // grant access
          if (roles.includes('admin')) return next();

          // user doesn't have access
          next('/?err=403');
        });

        return;
      }

      let { roles } = user.app_metadata;

      // grant access
      if (roles.includes('admin')) return next();

      // user doesn't have access
      return next('/?err=403');
    },
    children: [
      {
        path: '/',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/Dash.vue'),
      },
      {
        path: 'app-header',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/AdminAppHeader.vue'),
      },
      {
        path: 'about-me',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/AdminAboutMe.vue'),
      },
      {
        path: 'testimonials',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/AdminTestimonials.vue'),
      },
      {
        path: 'interventions',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/AdminInterventions.vue'),
      },
      {
        path: 'encouragement',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/AdminEncouragement.vue'),
      },
      {
        path: 'contact',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/AdminContact.vue'),
      },
      {
        path: 'app-footer',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/AdminAppFooter.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
