<template>
  <div class="section-light about-me" id="about-me">
    <div class="container">
      <div class="about-me">
        <h1 class="title has-text-centered section-title has-text-link">{{title}}</h1>
      </div>
      <div class="columns">
        <div class="column is-6 has-vertically-aligned-content"
            data-aos="fade-right">
          <div class="content" v-html="bio">
          </div>
          <div class="is-divider"></div>
          <div class="columns about-links">
            <div class="column">
              <p class="heading">
                <strong>Email Me</strong>
              </p>
              <p class="subheading">
                {{email}}
              </p>
            </div>
            <div class="column">
              <p class="heading">
                <strong>Follow Me</strong>
              </p>
              <p class="subheading">
                <a v-for="(account, i) in follow"
                   :key="`socialMedia-${i}`"
                   class="follow-icon has-text-primary"
                   target="_blank"
                   rel="noopener"
                   :href="account.destination"
                   :aria-label="account['social-media']">
                  <font-awesome-icon :icon="['fab', account['social-media']]" size="2x"/>
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="column is-6 right-image " data-aos="fade-left">
          <picture>
            <source srcset="../assets/ThorntonTherapeutic.webp" type="image/webp">
            <source srcset="../assets/ThorntonTherapeutic.jpg" type="image/jpeg">
            <img class="is-rounded"
                 src="../assets/ThorntonTherapeutic.webp"
                 alt="Ashlea Ann"
            />
          </picture>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      bio: String,
      email: String,
      follow: Array,
      image: String,
      title: String
    }
  }
</script>
