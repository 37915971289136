export const getSection = async (sectionComponent, token) => {
    return await fetch(`/.netlify/functions/actions?a=get-section&component=${sectionComponent}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(res => res.json());
}

export const getSettings = async (token) => {
    let headers = {Authorization: `Bearer ${token}`};

    let req = await fetch('/.netlify/functions/actions?a=settings', token && {headers}); // perhaps not very readable, but I couldn't help myself

    return req.json();
}

export const updateSection = async ({token, component, attributes}) => {
    let headers = {Authorization: `Bearer ${token}`};

    let req = await fetch('/.netlify/functions/actions?a=update-section', {
        method: 'POST',
        headers,
        body: JSON.stringify({component, attributes})
    });

    return req.json();
}

export const updateSectionOrder = async ({token, sectionsArray}) => {
    let headers = {Authorization: `Bearer ${token}`};

    let req = await fetch('/.netlify/functions/actions?a=sort-sections', {
        method: 'POST',
        headers,
        body: JSON.stringify(sectionsArray)
    });

    return req.json();
}