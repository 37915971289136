<template>
  <form id="toTop-form" @click.prevent="scrollTo('#home')" action="#home">
    <button id="toTop-btn" title="Go to top" class="has-background-primary">
      <font-awesome-icon :icon="['fal', 'angle-up']" />
    </button>
  </form>
</template>

<script>
  import { scrollTo } from '../utils';
  
  export default {
    methods: {
      scrollTo: function (selector) {
        scrollTo(selector);
      }
    }
  }
</script>
