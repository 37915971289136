<template>
    <div id="app">
        <Preloader v-if="!removeLoader" :class="`${fadeClass} preloader-wrapper`" />
        <scroll-up />
        <app-header v-if="settings.appHeader"
                    v-bind="settings.appHeader.attributes"
                    :hidden="hiddenMenuItems" />
        <main-content v-if="settings.sections">
            <component v-for="section in settings.sections"
                       :is="section.component"
                       :key="section.id"
                       v-bind="section.attributes" />
        </main-content>
        <app-footer />

        <notification class="is-success"
                      v-if="showContactSuccess"
                      @closeNotification="showContactSuccess = false">
            Thanks for reaching out{{fName}}! I'll get in touch soon. {{uEmail ? `Look for an email to ${uEmail}` : ``}}
        </notification>

        <notification class="is-danger"
                      v-if="show403"
                      @closeNotification="show403 = false">
            Admin access only. You are not authorized.
        </notification>

    </div>
</template>

<script>
    import { homeScrollListener } from '../utils';
    import ScrollUp from '../components/scrollUp';
    import AppHeader from '../components/app-header';
    import MainContent from '../components/main-content';
    import AboutMe from '../components/about-me';
    import Encouragement from '../components/encouragement';
    import AppFooter from '../components/appFooter';
    import Contact from '../components/contact';
    import Notification from '../components/Notification';
    import Interventions from '../components/interventions';
    import Testimonials from '../components/testimonials';
    import Preloader from '../components/preloader';
    import AOS from 'aos';
    import { getSettings } from '../utils/api';

    export default {
        name: 'App',
        components: {
            Interventions,
            ScrollUp,
            AppHeader,
            MainContent,
            AboutMe,
            Encouragement,
            AppFooter,
            Contact,
            Notification,
            Testimonials,
            Preloader
        },
        data() {
            return {
                showContactSuccess: false,
                show403: false,
                fName: '',
                uEmail: '',
                hiddenMenuItems: [],
                settings: {},
                removeLoader: false,
                fadeClass:''
            }
        },
        mounted() {
            let { fName, uEmail } = localStorage;
            let params = new URLSearchParams(window.location.search);

            // listen for scroll
            window.addEventListener('scroll', homeScrollListener);

            if (fName) this.fName = ` ${fName}`;
            if (uEmail) this.uEmail = uEmail;

            if (params.get('contacted')) {
                this.showContactSuccess = true;
                this.hiddenMenuItems.push('contact');
                this.removeLoader = true;
            }

            if (params.get('err') === '403') {
                this.show403 = true;
            }

            getSettings().then(settings => {
                this.settings = settings;
                this.fadeClass = 'opacity-0';

                setTimeout(() => {
                    this.removeLoader = true;
                    this.$nextTick().then(() => {
                        AOS.init({
                            easing: 'ease-out',
                            duration: 500
                        });
                    });
                }, 500);
            });
        },
        destroyed() {
            window.removeEventListener('scroll', homeScrollListener);
        }
    }
</script>

<style>
    .opacity-0 {
        opacity: 0 !important;
    }
    .preloader-wrapper {
        opacity: 1;
        transition: opacity .5s ease-out;
    }
</style>