<template>
    <div class="column is-half" data-aos="zoom-in-up">
        <div class="columns is-vcentered">
            <div class="column is-9">
                <div class="content is-medium">
                    <blockquote>
                        <p>{{testimonial.quote}}</p>
                        <p class="has-text-right">
                            <img class="is-pulled-left is-hidden-desktop m-width-50" :src="getAvatar(testimonial.gender)" alt="Profile">
                            <em>-- {{testimonial.author}}</em>
                        </p>
                    </blockquote>
                </div>
            </div>
            <div class="column is-3 is-hidden-touch">
                <img :src="getAvatar(testimonial.gender)" alt="Profile">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            testimonial: Object
        },
        methods: {
            getAvatar: function (gender) {
                return require(`@/assets/profile_pic_${gender}.svg`)
            }
        }
    }
</script>

<style scoped>

</style>