<template>
  <!--section-dark contact -->
  <div class="section has-background-white-ter" id="contact">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title has-text-centered section-title has-text-link">
            Get in touch
          </h1>
        </div>
      </div>
      <div class="columns" data-aos="fade-in-up" data-aos-easing="linear">
        <div class="column is-8 is-offset-2">
          <form @submit="checkConsent"
                name="contact-request-for-ashlea-ann"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                action="/?contacted=true">
            <input type="hidden" name="form-name" value="contact-request-for-ashlea-ann" />
            <div class="field">
              <label class="label" for="form-name">First Name</label>
              <div class="control has-icons-left">
                <input class="input" 
                       type="text" 
                       placeholder="What should I call you?" 
                       name="Name" 
                       id="form-name" 
                       v-model="fName"/>
                <span class="icon is-small is-left"><font-awesome-icon :icon="['fal', 'user']" /></span>
              </div>
            </div>
            <div class="field">
              <label class="label" for="form-email">Email</label>
              <div class="control has-icons-left">
                <input class="input" 
                       type="email" 
                       placeholder="Ex. hello@example.com" 
                       name="Email" 
                       id="form-email" 
                       v-model="uEmail"/>
                <span class="icon is-small is-left"><font-awesome-icon :icon="['fal', 'envelope']" /></span>
              </div>
            </div>
            <div class="field">
              <label class="label" for="hopeful-to-address">
                What am I most hopeful to address while accessing brief supportive counseling?
              </label>
              <div class="control">
                  <textarea id="hopeful-to-address" 
                            class="textarea" 
                            name="hopeful-to-address" 
                            v-model="hopefulToAddress">
                  </textarea>
              </div>
            </div>
            <div class="field">
              <label class="label" for="barriers-to-self-care">
                What are my barriers to self care and or mental health support?
              </label>
              <div class="control">
                <textarea id="barriers-to-self-care" 
                          class="textarea" 
                          name="barriers-to-self-care" 
                          v-model="barriersToSelfCare">
                </textarea>
              </div>
            </div>
            <div class="field">
              <label class="label" for="previous-counseling">Have you previously been engaged in counseling, if so, what concern specifically?</label>
              <div class="control">
                <textarea id="previous-counseling" 
                          class="textarea" 
                          name="previous-counseling" 
                          v-model="previousCounseling">
                </textarea>
              </div>
            </div>
            <div class="field">
              <label class="checkbox">
                <input type="checkbox" v-model="consented">
                By clicking this box, I give my consent for Thornton Therapeutic to reach out to in response via email provided regarding possible counseling services.
                <br>
                *Contacting me is not a platform for crises support. In case of emergency please all 911, head to your nearest emergency room or call a crisis line +1 (800) 273-8255.
              </label>
            </div>
            <div class="field">
              <div class="control">
                <button type="submit" class="button submit-button is-primary">
                  Get in touch
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        consented: false,
        previousCounseling: '',
        barriersToSelfCare: '',
        hopefulToAddress: '',
        fName: '',
        uEmail: '',
      }
    },
    mounted() {
      let { previousCounseling, barriersToSelfCare, hopefulToAddress, fName, uEmail} = localStorage;
      
      if (previousCounseling) this.previousCounseling = previousCounseling;
      if (barriersToSelfCare) this.barriersToSelfCare = barriersToSelfCare;
      if (hopefulToAddress) this.hopefulToAddress = hopefulToAddress;
      if (fName) this.fName = fName;
      if (uEmail) this.uEmail = uEmail; 
    },
    watch: {
      previousCounseling(newPreviousCounseling) {
        localStorage.previousCounseling = newPreviousCounseling;
      },
      barriersToSelfCare(newBarriersToSelfCare) {
        localStorage.barriersToSelfCare = newBarriersToSelfCare;
      },
      hopefulToAddress(newHopefulToAddress) {
        localStorage.hopefulToAddress = newHopefulToAddress
      },
      fName(newFName) {
        localStorage.fName = newFName;
      },
      uEmail(newUEmail) {
        localStorage.uEmail = newUEmail;
      }
    },
    methods: {
      checkConsent: function (e) {
        if (!this.consented) {
          e.preventDefault();
          alert('Please check the consent box in order to contact me.')
        }
        
      }
    }
  }
</script>
