<template>
  <!--section-color services-->
  <div class="section has-background-primary has-text-white" id="services">
    <div class="container">
      <div class="columns">
        <div class="column about-me">
          <h1 class="title has-text-centered section-title has-text-white">{{title}}</h1>
        </div>
      </div>
        <div class="columns is-multiline">
          <intervention v-for="(intervention, i) in interventions"
                        :key="`intervention-${i}`"
                        :title="intervention.title"
                        :icon="['fal', intervention.icon]" />
        </div>
      </div>
    </div>
</template>

<script>
  import Intervention from './intervention';
  
  export default {
    props: {
      title: String,
      interventions: Array
    },
    components: {
      Intervention
    }
  }
</script>
