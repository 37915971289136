<template>
  <div class="header-wrapper" id="home">
    <!-- Begin Hero -->
    <section class="hero is-fullheight">
      <div class="hero-head">
        <!-- Begin Mobile Nav -->
        <nav class="navbar is-hidden-tablet is-relative">
          <!-- Begin Burger Menu -->
          <div class="navbar-brand">
            <div @click="toggleMobileMenu" :class="{'make-x': showMobileNav}" class="navbar-burger burger" data-target="mobile-nav">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <!-- End Burger Menu -->
          <div ref="navMenu" id="mobile-nav" class="navbar-menu" :class="{'is-active': showMobileNav}">
            <div class="navbar-end">
              <div class="navbar-item">
                <a @click.prevent="smoothScroll('#about-me')" class="navbar-item" href="#about-me">
                  About Me
                </a>
              </div>

              <div class="navbar-item">
                <a @click.prevent="smoothScroll('#testimonials')" class="navbar-item" href="#testimonials">
                  Testimonials
                </a>
              </div>

              <div class="navbar-item">
                <a @click.prevent="smoothScroll('#services')" class="navbar-item" href="#services">
                  Interventions
                </a>
              </div>

              <div class="navbar-item">
                <a @click.prevent="smoothScroll('#encouragement')" class="navbar-item" href="#encouragement">
                  Encouragement
                </a>
              </div>

              <div v-if="!hidden.includes('contact')" class="navbar-item">
                <a @click.prevent="smoothScroll('#contact')" class="navbar-item" href="#contact">
                  Contact
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <!-- End Mobile Nav -->
      <!-- Begin Hero Content-->
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 v-if="topLine" class="subtitle">{{topLine}}</h1>
          <h2 class="title is-fun">{{middleLine}}</h2>
          <h1 class="title has-text-white is-4 pad-top-25">{{bottomLine}}</h1>
        </div>
      </div>
      <!-- End Hero Content-->
      <!-- Begin Hero Menu -->
      <div class="hero-foot ">
        <div class="hero-foot--wrapper">
          <div class="columns">
            <div class="column is-12 hero-menu-desktop has-text-centered">
              <ul>
                <li :class="{'is-active': section === '#about-me'}">
                  <a class="has-text-primary"
                     @click.prevent="smoothScroll('#about-me')"
                     href="#about-me">
                    About Me
                  </a>
                </li>

                <li :class="{'is-active': section === '#testimonials'}">
                  <a class="has-text-primary"
                     @click.prevent="smoothScroll('#testimonials')"
                     href="#testimonials">
                    Testimonials
                  </a>
                </li>

                <li :class="{'is-active': section === '#services'}">
                  <a class="has-text-primary"
                     @click.prevent="smoothScroll('#services')"
                     href="#services">
                    Interventions
                  </a>
                </li>

                <li :class="{'is-active': section === '#encouragement'}">
                  <a class="has-text-primary"
                     @click.prevent="smoothScroll('#encouragement')"
                     href="#encouragement">
                    Encouragement
                  </a>
                </li>

                <li v-if="!hidden.includes('contact')"  :class="{'is-active': section === '#contact'}">
                  <a class="has-text-primary"
                     @click.prevent="smoothScroll('#contact')"
                     href="#contact">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- End Hero Menu -->
    </section>
    <!-- End Hero -->
  </div>
</template>

<script>
  import { scrollTo } from '@/utils';
  
  export default {
    props: {
      hidden: {
        type: Array,
        default: () => []
      },
      default: [],
      topLine: String,
      middleLine: String,
      bottomLine: String,
    },
    data() {
      return {
        section: '#home',
        showMobileNav: false
      }
    },
    methods: {
      smoothScroll: function (selector) {
        scrollTo(selector);
        this.section = selector;
      },
      toggleMobileMenu: function () {
        let menu = this.$refs['navMenu'];
        this.showMobileNav = !this.showMobileNav;
        
        setTimeout(() => {
          if (this.showMobileNav) {
            menu.classList.add('in');
          } else {
            menu.classList.remove('in');
          }
        })
      }
    }
  }
</script>
