<template>
  <div class="notification">
    <button @click="closeNotification" class="delete"></button>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    methods: {
      closeNotification: function () {
        this.$emit('closeNotification');
      }
    }
  }
</script>

<style scoped>
  .notification {
    position: absolute;
    top:1.5rem;
    right: 1.5rem;
  }
</style>
