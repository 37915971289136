import './scss/app.scss';
import 'aos/dist/aos.css';
import { check_webp_feature } from './utils/utils';
import smoothscroll from 'smoothscroll-polyfill';
import Vue from 'vue';
import App from './App.vue';
import netlifyIdentity from 'netlify-identity-widget';
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faSpinnerThird, 
  faAngleUp, 
  faDownload, 
  faBus, 
  faUpload, 
  faCode, 
  faRocket, 
  faPaintBrush,
  faMeteor,
  faPaperPlane,
  faEnvelope,
  faUser,
  faHeadSideVirus,
  faCommentMedical,
  faUsersMedical,
  faHandsHeart,
  faUserInjured,
  faPrayingHands,
  faBrain,
  faProcedures,
  faHeadSideMedical,
  faHeartBroken,
  faTachometerAltSlow,
  faBorderBottom,
  faBorderTop,
  faBold,
  faItalic,
  faUnderline,
  faParagraph,
  faList,
  faListOl,
  faTrash,
  faPlusCircle
} from '@fortawesome/pro-light-svg-icons';
import { faGripVertical } from '@fortawesome/pro-regular-svg-icons'
import { 
  faInstagram, 
  faLinkedin,
  faFacebook,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from './router';

window.netlifyIdentity = netlifyIdentity;


if (!window.id_initiated) {
  window.netlifyIdentity.init();
  window.id_initiated = true;
}

smoothscroll.polyfill();

library.add(
  faSpinnerThird, 
  faAngleUp, 
  faDownload, 
  faBus, 
  faUpload, 
  faCode, 
  faRocket, 
  faPaintBrush,
  faMeteor,
  faPaperPlane,
  faEnvelope,
  faUser,
  faHeadSideVirus,
  faCommentMedical,
  faUsersMedical,
  faHandsHeart,
  faUserInjured,
  faPrayingHands,
  faBrain,
  faProcedures,
  faHeadSideMedical,
  faHeartBroken,
  faInstagram,
  faLinkedin,
  faGripVertical,
  faTachometerAltSlow,
  faBorderBottom,
  faBorderTop,
  faBold,
  faItalic,
  faUnderline,
  faParagraph,
  faList,
  faListOl,
  faFacebook,
  faTwitter,
  faTrash,
  faPlusCircle
);

// check for webp
check_webp_feature()
    .then(() => {
      window.document.body.classList.add('has-webp');
    })
    .catch(() => {
      window.document.body.classList.add('no-webp')
    })
    .finally(() => {
      Vue.component('font-awesome-icon', FontAwesomeIcon);

      Vue.config.productionTip = false;

      new Vue({
        router,
        render: h => h(App)
      }).$mount('#app');

      Vue.nextTick()
        .then(() => {
        Array.from(document.querySelectorAll('#netlify-identity-widget'))
            .forEach((el, i) => i > 0 && el.parentNode.removeChild(el));
        });
    });
