<template>
  <div class="column is-3 has-text-centered box-zooms-on-hover is-relative" 
       data-aos="fade-in" 
       data-aos-easing="linear">
    <font-awesome-icon :icon="icon" size="3x" />
    <hr />
    <h2 class="title is-4 has-text-white is-marginless">
      {{title}}
    </h2>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      icon: Array
    }
  }
</script>
