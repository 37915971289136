export function scrollTo(selector) {
  let el = document.querySelector(selector);
  
  el.scrollIntoView({behavior: 'smooth'});
}

export function showHideScrollUpButton(topOffset) {
  let el = document.getElementById('toTop-form');
  
  if (document.body.scrollTop > topOffset || document.documentElement.scrollTop > topOffset) {
    el.style.display = 'block';
    setTimeout(() => {
      el.style.opacity = '1';
    }, 0);
  } else {
    el.style.opacity = '0';
    
    setTimeout(() => {
      el.style.display = 'none';
    }, 400);
  }
}

export function homeScrollListener() {
  return showHideScrollUpButton(50);
}

export function addIGScriptIfNotYetAdded() {
  if (document.querySelector('script[src="//www.instagram.com/embed.js"]')) {
    return;
  }

  let script = document.createElement('script');
  script.src = '//www.instagram.com/embed.js';
  script.async = true;
  script.defer = true;

  document.body.appendChild(script);
}
